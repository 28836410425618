import React, { useState } from 'react';
import "./ugemr-metrics/Metrics.css";
import { ArrowRight } from "@carbon/react/icons";
import { Button, Modal } from "@carbon/react";

const ViewButton = ({ district, duty_station_type, dateFrom, dateTo }) => {
  const [showModal, setShowModal] = useState(false);
  const [facilities, setFacilities] = useState([]);
   
  // Function to fetch data from the API
  const fetchFacilities = async () => {
    try {
      const response = await fetch(`https://sgbv.caringsoulshomecare.com/api/?cmd=getDistrictDutyStation&district=${district}&duty_station_type=${0}&date_from=${dateFrom}&date_to=${dateTo}`);

      const data = await response.json();
      if (data.status === "ok") {
        setFacilities(data.results);
      } else {
        console.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Show the modal and fetch data when the button is clicked
  const handleViewClick = () => {
    setShowModal(true);
    fetchFacilities();
  };

  // Close the modal
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Button
        className="view-button"
        kind="ghost"
        renderIcon={() => (
          <ArrowRight size={16} className="arrow-icon" />
        )}
        iconDescription="View"
        onClick={handleViewClick}
      >
        View
      </Button>

      {/* Modal to display the facilities */}
      <Modal
        open={showModal}
        modalHeading="Facilities that Reported"
        passiveModal
        onRequestClose={handleClose}
        className="custom-modal"
      >
        <div className="facility-list">
          {facilities.length > 0 ? (
            facilities.map((facility, index) => (
              <div key={index} className="facility-item">
                <span className="facility-name">{facility.duty_station}</span>
                <span className="facility-total">{facility.total} reports</span>
              </div>
            ))
          ) : (
            <div className="no-data">No facilities reported.</div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ViewButton;
