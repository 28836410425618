 
  
  export const pieChartRDEPOCOptions = {
    title: "Male Vs Female",
    resizable: true,
    height: "400px",
    color: {
      scale: {
        Male: "#009d9a",
        Female: "#9f1853"
      }
    },
  }

  export const pieChartReportOptions = {
    title: "Police Station Vs Health Center",
    resizable: true,
    height: "400px",
    color: {
      scale: {
        Police: "#009d9a",
        HealthCenter: "#9f1853"
      }
    },
  }
    
  
   
  