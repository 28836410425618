import React, { useState, useEffect } from 'react';
import "./components/ugemr-metrics/Metrics.css";
import { Content, Dropdown } from "@carbon/react";
import "@carbon/charts/styles.css";
import Metrics from "./components/ugemr-metrics/Metrics";
import { DateFilterInput } from "./components/date-picker/date-picker"; 

const HomeComponent = () => {
  const currentDate = new Date();
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
  const endOfWeek = new Date(currentDate);
  endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()));

  const [dateArray, setDateArray] = useState([startOfWeek, endOfWeek]);
  const [selectedDistrict, setSelectedDistrict] = useState("Mubende");
  const [districts, setDistricts] = useState([]); // State to hold the fetched districts

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await fetch('https://sgbv.caringsoulshomecare.com/api/?cmd=getDistricts');
        const data = await response.json();
        if (data.status === "ok") {
          setDistricts(data.results.map(item => item.district));
        }
      } catch (error) {
        console.error('Error fetching districts:', error);
      }
    };

    fetchDistricts();
  }, []); // Empty dependency array means this runs once when the component mounts

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.selectedItem);
  };
 
  const updateDashboardMetrics = () => {
    // This function can be used to trigger any additional actions when the dashboard needs to be updated
  }; 
  return (
    <>
      <header className="metrics-header">
        <div className="header-label">SGBV Dashboard</div>
      </header>
      <Content className="metrics-body">
        <div className="switcher-date-container">
          <div className="switcher-container-width">
            <Dropdown
              id="district-dropdown"
              items={districts}
              label="Select District" 
              onChange={handleDistrictChange}
              selectedItem={selectedDistrict}
            />
          </div>
          <div className="date-container-width date-input-container">
            <DateFilterInput
              handleOnChangeRange={setDateArray}
              updateDashboard={updateDashboardMetrics}
              dateValue={dateArray}
            />
          </div>
        </div>
        <Metrics district={selectedDistrict} dateArray={dateArray} />
      </Content>
      <footer className="footer">
        <div className="rights-panel">© 2025 All rights reserved</div>
        <div>Monitoring & Evaluation Technical Support (METS)</div>
      </footer>
    </>
  );
};

export default HomeComponent;
