import React, { useState, useEffect } from "react";
import { StackedBarChart } from "@carbon/charts-react";
import "@carbon/charts/styles.css";

const StackedBarChartByAge = ({ district, dateFrom, dateTo }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://sgbv.caringsoulshomecare.com/api/?cmd=getAgeByDistrictDutyStation&district=${district}&date_from=${dateFrom}&date_to=${dateTo}`
        );
        const json = await response.json();
        if (json.status === "ok") {
          // Transform API data into chart format
          const transformedData = json.results.flatMap((item) => {
            const ageGroups = [
              "below_9",
              "age_10_14",
              "age_15_19",
              "age_20_24",
              "age_25_29",
              "age_30_34",
              "age_35_39",
              "age_40_44",
              "age_45_49",
              "age_50_plus",
            ];
            return ageGroups.map((ageGroup) => ({
              group: ageGroup.replace(/_/g, " "), // Format age group labels
              key: ` ${item.duty_station_type}`,
              value: parseInt(item[ageGroup], 10),
            }));
          });
          setChartData(transformedData);
        } else {
          throw new Error("No data available");
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [district, dateFrom, dateTo]);

  const chartOptions = {
    title: "Age Distribution by Duty Station",
    axes: {
      left: {
        scaleType: "labels"
      },
      bottom: {
        stacked: true
      }
    },
    height: "400px",
  
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <StackedBarChart data={chartData} options={chartOptions} />
    </div>
  );
};

export default StackedBarChartByAge;
